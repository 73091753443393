import React, { useState } from "react"
import { Link, navigate } from "gatsby"
import styled from "@emotion/styled"
import { Box, Flex } from "@rebass/grid/emotion"

import theme from "../../../../../nutrafol-ui-kit/styles/theme"
import { segmentEvent } from "@nutrafol/nutrafol-ui-kit/utils/segmentfunctions"
import { ButtonWild } from "@nutrafol/nutrafol-ui-kit/dist/ButtonWild"
import { TextElement } from "@nutrafol/nutrafol-ui-kit/dist/TextElement"

import { isIpad } from "../../../../utils/general"

import RightArrowSubMenu from "../../../../assets/icons/right-arrow-sub-menu.svg"

const SubItemWrapper = styled(Box)`
  width: 100%;
`
const SubItem = styled(Box)``

const DropdownMenu = styled.div`
  visibility: hidden;
  opacity: 0;
  position: absolute;
  top: 72px;
  background: transparent;
  z-index: 12;
  transition:
    visibility 0s,
    opacity 0.1s ease-in-out;
  left: 50%;
  width: 206px;
  transform: translateX(-50%);
  border-top: 5px solid transparent;

  // NT2-16598 variant style overrides

  max-width: 925px !important;
  .shop-items {
    width: 38% !important;
    min-width: 330px;
    margin-right: 40px !important;
  }
  .shop-sub-items {
    max-width: 195px !important;
    padding-left: 16px !important;
  }
  .shop-service-items {
    max-width: 245px;
  }

  .row {
    background-color: ${theme.wild.color.nova.base.white} !important;
    .box-left {
      width: 100% !important;
    }
  }

  &.dropdown-shop-desktop {
    left: 0;
    width: 100%;
    transform: none;

    .row {
      .box-left {
        width: 445px;
        @media (min-width: 1280px) {
          width: 553px;
        }
      }
    }
  }

  @media (min-width: 1024px) {
    top: 79px;
  }
`
const DropdownWrapper = styled(Flex)`
  background: ${theme.wild.color.nova.base.white};
  border-radius: 4px;
  box-shadow: 0 11px 15px 0 rgba(0, 87, 82, 0.06);
  padding: 30px 40px;
  flex-direction: row;
`
const MainBox = styled(Box)`
  .shop-items,
  .shop-service-items,
  .shop-sub-items {
    padding-top: 16px;
    padding-bottom: 8px;
  }

  .shop-items {
    width: 51%;
    background-color: ${theme.wild.color.nova.base.solid[20]};
  }
`
const NavItem = styled.div`
  a {
    display: block;
    padding: 8px 20px;
  }

  &.services-nav-item,
  &.shop-nav-item {
    svg {
      opacity: 0;

      path {
        fill: ${theme.wild.color.nova.brand.primary[80]};
      }
    }

    h4 {
      text-decoration: none;
    }

    &:hover {
      svg {
        opacity: 1;
      }
    }
  }
`
const SubNavItem = styled.div`
  a {
    display: block;
    padding: 8px 16px;
  }

  &.sub-service-nav-item,
  &.sub-shop-nav-item {
    svg {
      opacity: 0;
    }

    h4 {
      text-decoration: none;
    }

    &:hover {
      background: ${theme.wild.color.nova.base.solid[20]};
      svg {
        opacity: 1;
      }
    }
  }
`
const ButtonWrapper = styled.div`
  button {
    width: 100%;
  }
`

if (typeof window !== `undefined`) {
  // Polyfill for IE9+
  if (!window.Element.prototype.matches)
    window.Element.prototype.matches =
      window.Element.prototype.msMatchesSelector
  if (!window.Element.prototype.closest)
    window.Element.prototype.closest = function (selector) {
      var el = this
      while (el) {
        if (el.matches(selector)) {
          return el
        }
        el = el.parentElement
      }
    }
}

const removeActiveItemClass = (el, preventClick) => {
  const mouseLeftItem = hasHover(el)
  if (!!mouseLeftItem) {
    mouseLeftItem.classList.remove("hover")
  }
  if (preventClick) {
    el.preventDefault()
  }
  handleExpand(el)
}

const handleExpand = (el) => {
  if (el?.id) {
    ;[...document.querySelectorAll("nav [aria-expanded]")].forEach((a) => {
      const isHover = a?.parentElement?.classList?.contains("hover")
      a.ariaExpanded = isHover ? "true" : "false"
    })
  }
}

const hasHover = (e) => e.target.closest(".has-subnav.hover")

const buildCls = (subitem) => {
  return subitem.subtext ? "has-subtext" : "no-subtext"
}

const trackClicked = (text, destination) => {
  segmentEvent("Navigation Clicked", {
    module_location: "navigation",
    type: "button",
    text,
    destination,
  })
}

//force navigate for ipad https://nutrafol.atlassian.net/browse/NT2-8512
const onTouchStart = (url, state) => {
  if (isIpad()) {
    navigate(url, { state })
  }
}

const NavigationShopMenu = ({ data }) => {
  const [hoveredItem, setHoveredItem] = useState(null)

  const item = data[0]

  return (
    <>
      <DropdownMenu className={`dropdown dropdown-shop-desktop`}>
        <DropdownWrapper className="row">
          <Box className={`box-left`}>
            <MainBox>
              {item.children && (
                <Flex>
                  <Box className={"shop-items"} mr={30}>
                    <Flex flexWrap="wrap" flexDirection={"column"}>
                      <Box pl={"20px"} marginBottom={"8px"}>
                        <TextElement
                          element="h3"
                          text={item.childrenTitle}
                          className="body--large-b"
                        />
                      </Box>
                      {item.children.map((subitem, idx) => (
                        <Box
                          width={1}
                          key={`nav-item-${idx}`}
                          className={subitem.name}
                        >
                          <NavItem
                            onTouchStart={() => onTouchStart(subitem.url)}
                            className={`${item.name}-nav-item hover:bg-[#fff]`}
                          >
                            <Link
                              to={subitem.url}
                              key={`subitem-${idx}`}
                              onClick={() => {
                                trackClicked(subitem.name, subitem.url)
                              }}
                            >
                              <SubItemWrapper
                                onMouseEnter={() =>
                                  setHoveredItem(subitem.name)
                                }
                                onMouseLeave={() => setHoveredItem(null)}
                                onClick={removeActiveItemClass}
                              >
                                <SubItem
                                  className={`${subitem.name.toLowerCase()} ${buildCls(
                                    subitem,
                                    hoveredItem
                                  )} group ${hoveredItem ? "hovered" : ""}`}
                                >
                                  <Flex
                                    justifyContent={"space-between"}
                                    alignItems={"center"}
                                  >
                                    <Box>
                                      <TextElement
                                        element="h4"
                                        text={subitem.name}
                                        className={"body--large-b"}
                                        styles={{
                                          color: subitem.isMens
                                            ? `#0F3956`
                                            : theme.wild.color.nova.brand.primary[80],
                                        }}
                                      />
                                      <TextElement
                                        element="p"
                                        text={subitem.subtextDesktop}
                                        className="body--regular"
                                        styles={{
                                          color:
                                            theme.wild.color.nova.base.black,
                                        }}
                                      />
                                    </Box>
                                    <RightArrowSubMenu />
                                  </Flex>
                                </SubItem>
                              </SubItemWrapper>
                            </Link>
                          </NavItem>
                        </Box>
                      ))}
                    </Flex>
                  </Box>
                  {item.subCategories && (
                    <Box className={"shop-sub-items"} width={"49%"}>
                      <Flex flexDirection="column">
                        <Box pl={"16px"} marginBottom={"8px"}>
                          <TextElement
                            element="h3"
                            text={item.subCategoriesTitle}
                            className="body--large-b"
                          />
                        </Box>
                        {item.subCategories.map((subCategory, idx) => (
                          <Box key={`sub-nav-item-${idx}`}>
                            <SubNavItem
                              onTouchStart={() =>
                                onTouchStart(subCategory.url, {
                                  anchor: subCategory.anchor,
                                })
                              }
                              className={`sub-${item.name}-nav-item`}
                            >
                              <Link
                                to={subCategory.url}
                                key={`subcategory-${idx}`}
                                onClick={() => {
                                  trackClicked(
                                    subCategory.name,
                                    subCategory.url
                                  )
                                }}
                                state={{ anchor: subCategory.anchor }}
                              >
                                <SubItemWrapper
                                  onMouseEnter={() =>
                                    setHoveredItem(subCategory.name)
                                  }
                                  onMouseLeave={() => setHoveredItem(null)}
                                  onClick={removeActiveItemClass}
                                >
                                  <SubItem
                                    className={`${subCategory.name.toLowerCase()} ${buildCls(
                                      subCategory,
                                      hoveredItem
                                    )}`}
                                  >
                                    <Flex
                                      justifyContent={"space-between"}
                                      alignItems={"center"}
                                    >
                                      <TextElement
                                        element="h4"
                                        text={subCategory.name}
                                        className="body--large"
                                        styles={{
                                          color:
                                            theme.wild.color.nova.base.black,
                                        }}
                                      />
                                      <RightArrowSubMenu />
                                    </Flex>
                                  </SubItem>
                                </SubItemWrapper>
                              </Link>
                            </SubNavItem>
                          </Box>
                        ))}
                      </Flex>
                    </Box>
                  )}
                  <Box
                    className="shop-service-items growthplan-plus-abtest-variant"
                    ml={40}
                    pl={16}
                  >
                    <Flex flexDirection="column">
                      <Box pl={"16px"} marginBottom={"8px"}>
                        <TextElement
                          element="h3"
                          text={item.servicesTitle}
                          className="body--large-b"
                        />
                      </Box>
                      {item.services.map((service, idx) => (
                        <Box
                          width={1}
                          key={`service-item-${idx}`}
                          className={service.name}
                        >
                          <SubNavItem
                            onTouchStart={() => onTouchStart(service.url)}
                            className={`sub-service-nav-item hover:bg-[#e8faf7]`}
                          >
                            <Link
                              to={service.url}
                              key={`serviceitem-${idx}`}
                              onClick={() => {
                                trackClicked(service.name, service.url)
                              }}
                            >
                              <SubItemWrapper
                                onMouseEnter={() =>
                                  setHoveredItem(service.name)
                                }
                                onMouseLeave={() => setHoveredItem(null)}
                                onClick={removeActiveItemClass}
                              >
                                <SubItem
                                  className={`${service.name.toLowerCase()} ${buildCls(
                                    service,
                                    hoveredItem
                                  )} group ${hoveredItem ? "hovered" : ""}`}
                                >
                                  <Flex
                                    justifyContent={"space-between"}
                                    alignItems={"center"}
                                  >
                                    <Box>
                                      <TextElement
                                        element="h4"
                                        text={service.name}
                                        className={
                                          "body--large text-master-base-black"
                                        }
                                      />
                                      <TextElement
                                        element="p"
                                        text={service.subtext}
                                        className="body--regular text-master-base-alt-alt"
                                      />
                                    </Box>
                                    <Box>
                                      <RightArrowSubMenu />
                                    </Box>
                                  </Flex>
                                </SubItem>
                              </SubItemWrapper>
                            </Link>
                          </SubNavItem>
                        </Box>
                      ))}
                    </Flex>
                  </Box>
                </Flex>
              )}
            </MainBox>
            <Box marginTop={"10px"}>
              <ButtonWrapper>
                <ButtonWild
                  secondary
                  arrowRight
                  label="See All Products"
                  className="desktop-button"
                  onClick={() => {
                    trackClicked("See All Products", "/products/")
                    navigate("/products/")
                  }}
                />
              </ButtonWrapper>
            </Box>
          </Box>
        </DropdownWrapper>
      </DropdownMenu>
    </>
  )
}

export default NavigationShopMenu
